// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer-div {
  font-size: 10pt;
  margin-right: 12px;
  margin-left: 12px;
}

/*

.footer-item{
    margin-left: 6px;
    margin-right: 6px;
}
*/
.right-side {
  float: right;
}

.footer-text-color {
  color: #666;
}

.footer-center {
  align: center;
}

.footer-text-center {
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/app/footer/footer.component.scss"],"names":[],"mappings":"AACA;EACI,eAAA;EACA,kBAAA;EACA,iBAAA;AAAJ;;AAGA;;;;;;CAAA;AAQA;EACI,YAAA;AADJ;;AAIA;EACI,WAAA;AADJ;;AAIA;EACM,aAAA;AADN;;AAIA;EACI,kBAAA;AADJ","sourcesContent":["\n.footer-div{\n    font-size: 10pt;\n    margin-right: 12px;\n    margin-left: 12px;\n}\n\n/*\n\n.footer-item{\n    margin-left: 6px;\n    margin-right: 6px;\n}\n*/\n\n.right-side{\n    float: right;\n}\n\n.footer-text-color{\n    color: #666;\n}\n\n.footer-center{\n      align: center;\n}\n\n.footer-text-center{\n    text-align: center;\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
