// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.test-main-panel {
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  height: 100%;
}

.test-top-panel-heading {
  background-color: #fff;
  border-left: solid 1px #c4c4c4;
  border-right: solid 1px #c4c4c4;
  border-bottom: solid 1px #c4c4c4;
  padding-left: 8px;
  padding-top: 12px;
  padding-bottom: 8px;
}

.test-panel-heading {
  background-color: #fff;
  border-top: solid 1px #c4c4c4;
  border-left: solid 1px #c4c4c4;
  border-right: solid 1px #c4c4c4;
  padding-left: 8px;
  padding-top: 12px;
  padding-bottom: 8px;
}

.test-panel-row {
  background-color: #fff;
  border-bottom: solid 1px #c4c4c4;
  border-left: solid 1px #c4c4c4;
  border-right: solid 1px #c4c4c4;
  padding-left: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 12pt;
}

.test-button {
  position: relative;
  left: 247px;
  top: 14px;
}

.test-label {
  font-size: 12pt;
}

.textInput {
  width: 525px;
}`, "",{"version":3,"sources":["webpack://./src/app/image-search/data-section/data-section-tabs/test/test/test.component.scss"],"names":[],"mappings":"AACA;EACI,QAAA;EACA,UAAA;EACA,WAAA;EACA,SAAA;EACA,YAAA;AAAJ;;AAGA;EACI,sBAAA;EACA,8BAAA;EACA,+BAAA;EACA,gCAAA;EACA,iBAAA;EACA,iBAAA;EACA,mBAAA;AAAJ;;AAIA;EACI,sBAAA;EACA,6BAAA;EACA,8BAAA;EACA,+BAAA;EACA,iBAAA;EACA,iBAAA;EACA,mBAAA;AADJ;;AAIA;EACI,sBAAA;EACA,gCAAA;EACA,8BAAA;EACA,+BAAA;EACA,iBAAA;EACA,gBAAA;EACA,mBAAA;EACA,eAAA;AADJ;;AAKA;EACI,kBAAA;EACA,WAAA;EACA,SAAA;AAFJ;;AAKA;EACI,eAAA;AAFJ;;AAKA;EACI,YAAA;AAFJ","sourcesContent":["\n.test-main-panel {\n    top: 0px;\n    right: 0px;\n    bottom: 0px;\n    left: 0px;\n    height: 100%;\n}\n\n.test-top-panel-heading {\n    background-color: #fff;\n    border-left: solid 1px #c4c4c4;\n    border-right: solid 1px #c4c4c4;\n    border-bottom: solid 1px #c4c4c4;\n    padding-left: 8px;\n    padding-top: 12px;\n    padding-bottom: 8px;\n}\n\n\n.test-panel-heading {\n    background-color: #fff;\n    border-top: solid 1px #c4c4c4;\n    border-left: solid 1px #c4c4c4;\n    border-right: solid 1px #c4c4c4;\n    padding-left: 8px;\n    padding-top: 12px;\n    padding-bottom: 8px;\n}\n\n.test-panel-row {\n    background-color: #fff;\n    border-bottom: solid 1px #c4c4c4;\n    border-left: solid 1px #c4c4c4;\n    border-right: solid 1px #c4c4c4;\n    padding-left: 8px;\n    padding-top: 8px;\n    padding-bottom: 8px;\n    font-size: 12pt;\n}\n\n\n.test-button{\n    position:relative;\n    left: 247px;\n    top:14px;\n}\n\n.test-label{\n    font-size:12pt;\n}\n\n.textInput{\n    width:525px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
