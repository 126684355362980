// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.downloader-download-div {
  height: 250px;
  width: 500px;
  left: calc(50% - 250px);
}

.download-text {
  margin: 50px 10px 10px 10px;
  position: relative;
  top: 10px;
  color: #000;
  background-color: #dedddd;
  /* T  R  B  L*/
  padding: 10px 12px 12px 11px;
  font-size: 12pt;
  font-weight: 400;
}

.download-checkbox {
  margin: 14px 0px 0px 10px;
}

.data-retriever-info-text {
  position: absolute;
  top: 15px;
}`, "",{"version":3,"sources":["webpack://./src/app/cart/downloader-download/downloader-download.component.scss"],"names":[],"mappings":"AAAA;EAEI,aAAA;EACA,YAAA;EACA,uBAAA;AAAJ;;AAGA;EACI,2BAAA;EAEA,kBAAA;EACA,SAAA;EAEA,WAAA;EACA,yBAAA;EAEA,cAAA;EACA,4BAAA;EAEA,eAAA;EACA,gBAAA;AAJJ;;AAOA;EACI,yBAAA;AAJJ;;AAOA;EACI,kBAAA;EACA,SAAA;AAJJ","sourcesContent":[".downloader-download-div {\n\n    height: 250px;\n    width: 500px;\n    left: calc(50% - 250px);\n}\n\n.download-text {\n    margin: 50px 10px 10px 10px;\n\n    position: relative;\n    top: 10px;\n\n    color: #000;\n    background-color: #dedddd;\n\n    /* T  R  B  L*/\n    padding: 10px 12px 12px 11px;\n\n    font-size: 12pt;\n    font-weight: 400;\n}\n\n.download-checkbox {\n    margin: 14px 0px 0px 10px;\n}\n\n.data-retriever-info-text{\n    position: absolute;\n    top: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
