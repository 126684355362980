// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.red-asterisk {
  font-size: 16pt;
  font-weight: bold;
  color: #700;
  cursor: default;
}

.series-results-set {
  border: solid 1px #f4f4f4;
  border-radius: 5px;
  margin: 3px 0 2px 0;
  /*padding-bottom: 5px;*/
}

.subject-details-header {
  background: #f4f4f4;
  border: solid 1px #f4f4f4;
  width: 100%;
}

.study-table-header {
  background-color: #dedddd;
  height: 50px;
  font-weight: bold;
}

.study-header-tr {
  background-color: #dedddd;
  border: solid 10px #dedddd;
  height: 30px;
  font-weight: bold;
}

.arrow-span {
  padding-right: 6px;
}

.study-date-clicker:hover {
  text-decoration: none;
}

.study-date-clicker:link {
  text-decoration: none;
}

.ohif-study-button {
  width: 30px;
  padding: 2px 7px 1px 7px;
}

.hide {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/app/image-search/data-section/data-section-tabs/search-results/search-results-table/subject-study-details/subject-study-details.component.scss"],"names":[],"mappings":"AACA;EACE,eAAA;EACA,iBAAA;EACA,WAAA;EACA,eAAA;AAAF;;AAGA;EACE,yBAAA;EACA,kBAAA;EACA,mBAAA;EACA,uBAAA;AAAF;;AAIA;EACE,mBAAA;EACA,yBAAA;EACA,WAAA;AADF;;AAIA;EACE,yBAAA;EACA,YAAA;EACA,iBAAA;AADF;;AAIA;EACE,yBAAA;EACA,0BAAA;EACA,YAAA;EACA,iBAAA;AADF;;AAIA;EACE,kBAAA;AADF;;AAIA;EACE,qBAAA;AADF;;AAIA;EACE,qBAAA;AADF;;AAIA;EACE,WAAA;EACA,wBAAA;AADF;;AAKA;EACE,aAAA;AAFF","sourcesContent":["// TODO move this higher, we have something similar in series details\n.red-asterisk {\n  font-size: 16pt;\n  font-weight: bold;\n  color: #700;\n  cursor: default;\n}\n\n.series-results-set {\n  border: solid 1px #f4f4f4;\n  border-radius: 5px;\n  margin: 3px 0 2px 0;\n  /*padding-bottom: 5px;*/\n\n}\n\n.subject-details-header {\n  background: #f4f4f4;\n  border: solid 1px #f4f4f4;\n  width: 100%;\n}\n\n.study-table-header {\n  background-color: #dedddd;\n  height: 50px;\n  font-weight: bold;\n}\n\n.study-header-tr {\n  background-color: #dedddd;\n  border: solid 10px #dedddd;\n  height: 30px;\n  font-weight: bold;\n}\n\n.arrow-span {\n  padding-right: 6px;\n}\n\n.study-date-clicker:hover {\n  text-decoration: none;\n}\n\n.study-date-clicker:link {\n  text-decoration: none;\n}\n\n.ohif-study-button {\n  width: 30px;\n  padding: 2px 7px 1px 7px;\n}\n\n// FIXME this is a dup\n.hide {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
