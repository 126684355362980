// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.box-div {
  left: calc(50% - 200px);
}

.alert-box-header-text {
  position: absolute;
  top: 15px;
}

.alert-box-icon {
  height: 32px;
}`, "",{"version":3,"sources":["webpack://./src/app/common/components/alert-box/alert-box.component.scss"],"names":[],"mappings":"AAAA;EACI,uBAAA;AACJ;;AAEA;EACI,kBAAA;EACA,SAAA;AACJ;;AAEA;EACI,YAAA;AACJ","sourcesContent":[".box-div{\n    left: calc(50% - 200px);\n}\n\n.alert-box-header-text{\n    position: absolute;\n    top: 15px;\n}\n\n.alert-box-icon {\n    height: 32px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
