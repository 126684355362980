// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.results-pager-font {
  font-size: 9pt;
}

.selected {
  color: #fff;
  background-color: #686868;
}

.hide-button {
  display: none;
}

.pager {
  margin-right: 15px;
  margin-top: 12px;
  float: right;
}

.btn-nbia-pager {
  border: solid #ccc 1px;
  height: 32px;
  width: 32px;
  padding: 4px 0 0 10px;
}

.btn-nbia-pager-left {
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
}

.btn-nbia-pager-right {
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}`, "",{"version":3,"sources":["webpack://./src/app/common/components/search-results-pager/search-results-pager.component.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;AACJ;;AAEA;EACI,WAAA;EACA,yBAAA;AACJ;;AAEA;EACI,aAAA;AACJ;;AAEA;EACI,kBAAA;EACA,gBAAA;EAEA,YAAA;AAAJ;;AAIA;EACI,sBAAA;EACA,YAAA;EACA,WAAA;EACA,qBAAA;AADJ;;AAIA;EACI,8BAAA;EACA,2BAAA;AADJ;;AAIA;EACI,+BAAA;EACA,4BAAA;AADJ","sourcesContent":[".results-pager-font {\n    font-size: 9pt;\n}\n\n.selected {\n    color: #fff;\n    background-color: #686868;\n}\n\n.hide-button {\n    display: none;\n}\n\n.pager{\n    margin-right: 15px;\n    margin-top: 12px;\n\n    float:right;\n}\n\n\n.btn-nbia-pager{\n    border: solid #ccc 1px;\n    height: 32px;\n    width: 32px;\n    padding: 4px 0 0 10px;\n}\n\n.btn-nbia-pager-left{\n    border-bottom-left-radius: 3px;\n    border-top-left-radius: 3px;\n}\n\n.btn-nbia-pager-right{\n    border-bottom-right-radius: 3px;\n    border-top-right-radius: 3px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
